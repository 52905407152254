.checkbox {
	display: flex; align-items: center; justify-content: center;
	padding: 1px;
}

.checkbox .border {
	position: relative;
	border: 2px solid white;
	border-radius: 4px;
	width: 20px; height: 20px;
	cursor: pointer;
}

.checkbox .indicator {
	position: absolute; top: 1px; left: 1px; right: 1px; bottom: 1px; z-index: 1;
	background: white;
	border-radius: 2px;
	transform: scale(0);
}

.checkbox .indicator.checked {
	transform: scale(1);
}
