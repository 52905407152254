.update_user form {
	display: flex; flex-direction: column;
	justify-content: center; align-items: center;
	background: var(--lightBlue);
	width: fit-content;
	padding: 30px; margin: auto; margin-top: 15vw;
	border: 1px solid black; border-radius: 20px;
}

.update_user .my_input {
	display: flex; justify-content: center; align-items: center; flex-direction: column;
}

.update_user input, .update_user select, .update_user button { width: 300px; height: 30px; margin: 10px 0; }

.update_user .error_message { font-size: 12px; color: red; margin-bottom: 10px; }
