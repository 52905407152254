#dataTable_container {
	position: relative;
}

.dataTable {
	border-collapse: collapse;
	margin: 30px auto;
	max-width: 90%;
	font-size: 16px;
}

.dataTable tr, .dataTable th, .dataTable td {
	border: 1px solid red;
	padding: 10px;
	text-align: left;
}

.dataTable th > div {
	display: flex;
	justify-content: space-between;
}

.dataTable th > div > span {
	display: flex;
	margin-left: 5px;
}

.dataTable th > div > span > svg {
	cursor: pointer;
}

.dataTable th, .dataTable td {
	max-width: 120px; height: 50px;
	overflow-x: auto;
	padding: 5px;
}

.dataTable td span {
	padding: 5px;
	background: #5cb85c; color: white;
	display: inline-block; width: 100%; text-align: center;
	border: 1px solid black; border-radius: 5px;
}
.dataTable td span.sim {
	background: #5bc0de;
}
.dataTable td span.deny {
	background: #d9534f;
}

.dataTable tbody tr.deny:hover {
	background: #d9534f;
}
.dataTable tbody tr.accept:hover {
	background: #5cb85c;
}

.dataTable .type { min-width: 120px; }

/* .dataTable tbody tr.deny { background: #d9534f; } */

.dataTable .timestamp,
.dataTable .flux,
.dataTable .programme,
.dataTable .dispositif,
.dataTable .email,
.dataTable .webtag,
.dataTable .projet,
.dataTable .commentaire,
.dataTable .type,
.dataTable .select {
	max-width: fit-content;
	max-width: intrinsic;           /* Safari/WebKit uses a non-standard name */
	max-width: -moz-max-content;    /* Firefox/Gecko */
	max-width: -webkit-max-content; /* Chrome */
}

#show_all > input { outline: none; }
#show_all > span {
	cursor: pointer;
	padding: 5px;
	background: white; color: #333;
	margin-left: 10px;
}
