#selected {
	position: absolute; right: 30px; top: 30px;
	display: flex; justify-content: center; align-items: center;
}

#selected > span {
	cursor: pointer;
	margin-left: 10px;
	padding: 5px;
	background: white; color: #333;
}
