#home #head {
	display: flex; justify-content: space-around; align-items: center;
	width: 100%;
	padding: 30px;
}

#home #head > span {
	background: white; color: black;
	padding: 15px;
	cursor: pointer;
	width: 150px; height: 60px;
	display: flex; justify-content: center; align-items: center;
}

#home #body {
	display: flex; justify-content: center; align-items: center;
}
