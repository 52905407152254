#leadsForm .flex_container { display: flex; }
#leadsForm input {
	width: 200px; height: 45px;
	border: 1px solid #ccc;
	color: #333;
	background-color: #fff;
	font-size: 16px;
	padding: 5px;
	outline: none;
}

#leadsForm .react-datepicker-wrapper, .react-datepicker__close-icon { width: unset; }
