.dropdown {
	position: relative;
	color: #333;
	cursor: default;
	width: 200px; height: 45px;
}

.dropdown .control, .dropdown .selected_value { width: 100%; height: 100%; }

.dropdown .arrow {
	border-color: #999 transparent transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	content: " ";
	display: block;
	height: 0;
	margin-top: 0.3rem;
	position: absolute;
	right: 10px;
	top: 14px;
	width: 0;
}

.dropdown .arrow.open {
	border-color: transparent transparent #999;
	border-width: 0 5px 5px;
}

.dropdown .selected_value input {
	font-size: 16px;
	background-color: #fff;
	border: 1px solid #ccc;
	cursor: default;
	outline: none;
	padding: 5px;
	transition: all 200ms ease;
	width: 100%; height: 100%;
}

.dropdown .options {
	display: none;
	background-color: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	box-sizing: border-box;
	margin-top: -1px;
	max-height: 200px;
	overflow-y: auto;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 1000;
	-webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
	display: block;
}

.dropdown .option {
	box-sizing: border-box;
	color: rgba(51, 51, 51, 0.8);
	cursor: pointer;
	display: block;
	padding: 10px 5px;
}

.dropdown .option.selected,
.dropdown .option:hover {
	background-color: #f2f9fc;
	color: #333;
}
